import { mergeSxProps } from "@clipboard-health/ui-react";
import { DialogContent, type SxProps, type Theme } from "@mui/material";
import { forwardRef } from "react";

interface WorkplaceModalContentProps {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
}

function BaseWorkplaceModalContent(
  props: WorkplaceModalContentProps,
  ref: React.Ref<HTMLDivElement>
) {
  const { children, sx } = props;

  return (
    <DialogContent
      ref={ref}
      sx={mergeSxProps(
        {
          px: 7,
          pt: 0,
          // padding bottom to make sure the content isn't hidden by the page's floating footer
          pb: "200px",
        },
        sx
      )}
    >
      {children}
    </DialogContent>
  );
}

export const WorkplaceModalContent = forwardRef(BaseWorkplaceModalContent);
