import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";

import { OpenShiftCard } from "../../Shift/Open/Card";
import { OpenShiftsListLoadingState } from "../../Shift/Open/ListLoadingState";
import { type OpenShiftWorkplace } from "../../Shift/Open/types";
import { useOpenShiftListDataContext } from "../../Shift/Open/useOpenShiftListDataContext";
import { useVirtualShiftListContext } from "../../Shift/Open/useVirtualShiftListContext/useVirtualShiftListContext";
import { ShiftVirtualizedList } from "../../Shift/Open/VirtualizedList";
import { WorkplaceOpenShiftsEmptyState } from "./EmptyState";

interface WorkplaceOpenShiftsListProps {
  scrollRef: React.RefObject<HTMLDivElement>;
  workplace?: OpenShiftWorkplace;
}

export function WorkplaceOpenShiftsList(props: WorkplaceOpenShiftsListProps) {
  const { workplace, scrollRef } = props;

  const { listRef, virtualShiftListItems } = useVirtualShiftListContext();

  const isListEmpty = virtualShiftListItems.length === 0;

  const {
    onClickOpenShift,
    shiftsMissingRequirementsMap,
    isOpenShiftsLoading,
    isOpenShiftsError,
    isOpenShiftsSuccess,
    canLoadMore,
    loadMore,
    isLoadingMore,
  } = useOpenShiftListDataContext();

  if (isOpenShiftsError) {
    return <Text>Error loading shifts</Text>;
  }

  if (isOpenShiftsLoading) {
    return (
      <OpenShiftsListLoadingState
        texts={[
          "Finding you the best-paying shifts at this facility",
          "Analyzing shift opportunities just for you",
          "Your perfect shift is just a moment away",
          "Crunching the numbers to maximize your earnings",
          "Digging deep into facility schedules",
          "Searching high and low for the best shifts at this location",
          "Unlocking your next great opportunity at this facility",
        ]}
      />
    );
  }

  if (isOpenShiftsSuccess && isListEmpty) {
    return <WorkplaceOpenShiftsEmptyState />;
  }

  return (
    <ShiftVirtualizedList
      ref={listRef}
      scrollRef={scrollRef}
      items={virtualShiftListItems}
      isLoadingMore={isLoadingMore}
      renderItem={(shift) => {
        if (!isDefined(workplace)) {
          return null;
        }

        const { missingDocuments, showMissingDocumentsCount, isInstantReview } =
          shiftsMissingRequirementsMap.get(shift.id) ?? {};

        return (
          <OpenShiftCard
            key={shift.id}
            workers={[]}
            shift={shift}
            workplace={workplace}
            missingDocuments={missingDocuments ?? []}
            showMissingDocumentsCount={showMissingDocumentsCount ?? false}
            isInstantReview={isInstantReview ?? false}
            onClick={({ offer }) => {
              onClickOpenShift({ shift, workplace, offer });
            }}
          />
        );
      }}
      onEndReached={() => {
        if (canLoadMore) {
          loadMore();
        }
      }}
    />
  );
}
