import { isDefined } from "@clipboard-health/util-ts";
import { useGetExtraTimePaySettings } from "@src/appV2/Facilities/api/useGetExtraTimePaySettings";
import type { AxiosError } from "axios";

import { useWorkplaceNotesParsed } from "../useWorkplaceNotesParsed";
import { buildWorkplacePolicyHighlights } from "./buildWorkplacePolicyHighlights";
import type { WorkplaceHighlight } from "./types";

interface UseWorkplacePolicyHighlightsProps {
  workplaceId: string | undefined;
  lateCancellationPeriod: number;
  lateCancellationFeeHours: number;
  requiresLunchBreak?: boolean;
  providesRestBreaks?: boolean;
}

interface UseWorkplacePolicyHighlightsResult {
  highlights: WorkplaceHighlight[];
  isLoading: boolean;
}

export const useWorkplacePolicyHighlights = (
  props: UseWorkplacePolicyHighlightsProps
): UseWorkplacePolicyHighlightsResult => {
  const {
    workplaceId,
    lateCancellationPeriod,
    lateCancellationFeeHours,
    requiresLunchBreak,
    providesRestBreaks,
  } = props;

  const { data: workplaceNotes = {}, isLoading: isWorkplaceNotesLoading } = useWorkplaceNotesParsed(
    workplaceId ?? "",
    {
      enabled: isDefined(workplaceId),
      useErrorBoundary: false,
    }
  );

  const { data: extraTimePaySettings, isLoading: isExtraTimePayLoading } =
    useGetExtraTimePaySettings(workplaceId, {
      retry: (failureCount, error) => {
        // No need to retry if not found
        if ((error as AxiosError).response?.status === 404) {
          return false;
        }

        return failureCount < 3;
      },
      retryOnMount: false,
      refetchOnWindowFocus: (query) => query.state.status !== "error",
    });

  const isExtraTimePayEnabled =
    isDefined(extraTimePaySettings?.workdayOptions) ||
    isDefined(extraTimePaySettings?.weeklyOption);

  return {
    isLoading: isDefined(workplaceId) ? isWorkplaceNotesLoading || isExtraTimePayLoading : false,
    highlights: buildWorkplacePolicyHighlights({
      isExtraTimePayEnabled,
      lateCancellationPeriod,
      lateCancellationFeeHours,
      requiresLunchBreak,
      providesRestBreaks,
      workplaceNotes,
    }),
  };
};
