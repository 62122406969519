import { type UseModalState } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { FullScreenDialog } from "@src/appV2/lib/Dialogs";
import { StickyScrollPageHeader } from "@src/appV2/redesign/components/StickyScrollPageHeader";
import { useEffect, useRef } from "react";
import { generatePath, useLocation } from "react-router-dom";

import { Button } from "../../components/Button";
import { WorkplacePlacementsAdCard } from "../../Placements/Workplace/WorkplacePlacementsAdCard";
import { OpenShiftListDataProvider } from "../../Shift/Open/useOpenShiftListDataContext";
import { VirtualShiftListContextProvider } from "../../Shift/Open/useVirtualShiftListContext/useVirtualShiftListContext";
import {
  SHIFT_DISCOVERY_WORKPLACE_OPEN_SHIFTS_MODAL_PATH,
  SHIFT_DISCOVERY_WORKPLACE_PROFILE_MODAL_PATH,
} from "../../ShiftDiscovery/paths";
import { useShiftModalsDataContext } from "../../ShiftDiscovery/useShiftModalsDataContext";
import { useWorkplaceModalsDataContext } from "../../ShiftDiscovery/useWorkplaceModalsDataContext";
import { useScrollToTopOnStatusTap } from "../../useScrollToTopOnStatusTap";
import { WorkplaceModalContent } from "../Modal/Content";
import { WorkplaceModalFloatingFooter } from "../Modal/FloatingFooter";
import type { WorkplaceLocationState, WorkplaceProfile } from "../types";
import { WorkplaceOpenShiftsList } from "./List";
import { useWorkplaceOpenShiftsData } from "./useWorkplaceOpenShiftsData";

interface WorkplaceOpenShiftsModalProps {
  modalState: UseModalState;
  workplaceId: string;
  workplace?: WorkplaceProfile;
  isLoading: boolean;
}

export function WorkplaceOpenShiftsModal(props: WorkplaceOpenShiftsModalProps) {
  const { modalState, workplaceId, workplace, isLoading: isWorkplaceLoading } = props;
  const { name } = workplace ?? {};

  const {
    // we don't want to filter shifts in the workplace open shifts modal, we want users to see all shifts
    data: { unfilteredWorkplaceShifts: workplaceShifts, workplacesMap, rateNegotiationsMap },
    isError: openShiftsIsError,
    isLoading: openShiftsIsLoading,
    isSuccess: openShiftsIsSuccess,
    pagination,
  } = useWorkplaceOpenShiftsData(workplace);

  const { setBaseNavigationPathOverride } = useShiftModalsDataContext();
  const { generateModalPath } = useWorkplaceModalsDataContext();

  /**
   * When this modal is opened, we want to override the base navigation path
   * so that when shift modals are opened from here, they are rendered on top of this workplace modal
   */
  useEffect(() => {
    const path = generatePath(SHIFT_DISCOVERY_WORKPLACE_OPEN_SHIFTS_MODAL_PATH, {
      workplaceId,
    });

    setBaseNavigationPathOverride(path);

    return () => {
      setBaseNavigationPathOverride(undefined);
    };
  }, [setBaseNavigationPathOverride, workplaceId]);

  const scrollRef = useRef<HTMLDivElement>(null);

  useScrollToTopOnStatusTap(scrollRef);

  const location = useLocation<WorkplaceLocationState>();

  return (
    <FullScreenDialog skipTopSafeAreaPadding modalState={modalState} variant="tertiary">
      <OpenShiftListDataProvider
        openShifts={workplaceShifts}
        workplacesMap={workplacesMap}
        rateNegotiationsMap={rateNegotiationsMap}
        isOpenShiftsLoading={openShiftsIsLoading || isWorkplaceLoading}
        isOpenShiftsError={openShiftsIsError}
        isOpenShiftsSuccess={openShiftsIsSuccess}
        canLoadMoreShifts={Boolean(pagination?.hasNextPage)}
        isLoadingMoreShifts={pagination?.isFetchingNextPage ?? false}
        loadMoreShifts={() => {
          void pagination?.fetchNextPage();
        }}
      >
        <VirtualShiftListContextProvider shifts={workplaceShifts} workplacesMap={workplacesMap}>
          <StickyScrollPageHeader
            title={name ?? ""}
            isLoading={isWorkplaceLoading}
            scrollContainerRef={scrollRef}
            headerVariant="tertiary"
          />
          <WorkplaceModalContent ref={scrollRef} sx={{ display: "flex", flexDirection: "column" }}>
            <Stack spacing={8} sx={{ flex: 1 }}>
              <WorkplacePlacementsAdCard workplaceId={workplaceId} />

              <WorkplaceOpenShiftsList
                scrollRef={scrollRef}
                workplace={workplacesMap.get(workplaceId)}
              />
            </Stack>
          </WorkplaceModalContent>
          <WorkplaceModalFloatingFooter>
            {location.state?.origin !== "WORKPLACE_PROFILE_MODAL" && (
              <Button
                fullWidth
                variant="contained"
                sx={(theme) => ({
                  // !important is needed to override the default `box-shadow: none` on the button
                  boxShadow: `${theme.shadows[3]} !important`,
                })}
                href={generateModalPath(SHIFT_DISCOVERY_WORKPLACE_PROFILE_MODAL_PATH, {
                  workplaceId,
                })}
                locationState={{
                  origin: "WORKPLACE_OPEN_SHIFTS_MODAL",
                }}
                onClick={() => {
                  logEvent(APP_V2_USER_EVENTS.VIEWED_FACILITY_PROFILE, {
                    workplaceId,
                    trigger: "Workplace Open Shifts Modal",
                  });
                }}
              >
                View profile
              </Button>
            )}
          </WorkplaceModalFloatingFooter>
        </VirtualShiftListContextProvider>
      </OpenShiftListDataProvider>
    </FullScreenDialog>
  );
}
