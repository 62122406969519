import { DialogFooter } from "../../components/DialogFooter";

interface WorkplaceModalFloatingFooterProps {
  children: React.ReactNode;
}

export function WorkplaceModalFloatingFooter(props: WorkplaceModalFloatingFooterProps) {
  const { children } = props;

  return (
    <DialogFooter
      orientation="horizontal"
      sx={{ position: "absolute", bottom: 0, left: 0, right: 0 }}
    >
      {children}
    </DialogFooter>
  );
}
