import { Callout } from "@clipboard-health/ui-components";
import { RootPaths } from "@src/appV2/App/paths";
import { useCbhFlag } from "@src/appV2/FeatureFlags";
import { CbhFeatureFlag } from "@src/appV2/FeatureFlags/CbhFeatureFlag";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { WORKER_AVAILABILITIES_PATH } from "@src/appV2/WorkerAvailability";
import { useGetWorkerAvailabilityBannerContent } from "@src/appV2/WorkerAvailability/components/WorkerAvailabilityBanner/useGetWorkerAvailabilityBannerContent";
import { intersection } from "lodash";
import { useHistory } from "react-router-dom";

export function WorkerAvailabilityCallout() {
  const worker = useDefinedWorker();

  const history = useHistory();

  const agentAvailableQualifications =
    worker?.licensesData?.map((item) => item.qualification) ?? [];

  const { qualifications } = useCbhFlag(CbhFeatureFlag.ENABLE_WORKER_AVAILABILITY, {
    defaultValue: { qualifications: [] },
  });

  const isWorkerAvailabilityEnabled =
    intersection(qualifications, agentAvailableQualifications).length > 0;

  const bannerContent = useGetWorkerAvailabilityBannerContent({
    selectedDate: new Date(),
    timezone: worker.tmz,
    isWorkerAvailabilityEnabled,
  });

  if (!isWorkerAvailabilityEnabled) {
    return null;
  }

  return (
    <Callout
      title={bannerContent.title}
      iconType="calendar-clock"
      onClick={() => {
        history.push(`${RootPaths.APP_V2_HOME}/${WORKER_AVAILABILITIES_PATH}`);
      }}
    />
  );
}
