import { isDefined } from "@clipboard-health/util-ts";

import { WorkplaceNoteIdentifier } from "../constants";
import { type WorkplaceNotesRecord } from "../useWorkplaceNotesParsed";
import { WorkplaceHighlightType } from "./constants";
import {
  getWorkplaceHighlightLabel,
  type WorkplaceHighlightLabelOptions,
} from "./getWorkplaceHighlightLabel";
import type { WorkplaceHighlight } from "./types";

interface BuildWorkplacePolicyHighlightsProps {
  isExtraTimePayEnabled: boolean;
  lateCancellationPeriod: number;
  lateCancellationFeeHours: number;
  requiresLunchBreak?: boolean;
  providesRestBreaks?: boolean;
  workplaceNotes: WorkplaceNotesRecord;
}

export const buildWorkplacePolicyHighlights = ({
  isExtraTimePayEnabled,
  lateCancellationPeriod,
  lateCancellationFeeHours,
  requiresLunchBreak,
  providesRestBreaks,
  workplaceNotes,
}: BuildWorkplacePolicyHighlightsProps): WorkplaceHighlight[] => {
  const highlightTypes: WorkplaceHighlightType[] = [];
  const highlightLabelOptions: WorkplaceHighlightLabelOptions = {};

  if (isExtraTimePayEnabled) {
    highlightTypes.push(WorkplaceHighlightType.EXTRA_TIME_PAY);
  }

  const offersCancellationPayout = lateCancellationPeriod > 0 && lateCancellationFeeHours > 0;

  if (offersCancellationPayout) {
    highlightTypes.push(WorkplaceHighlightType.CANCELLATION_PAYOUT);
    highlightLabelOptions.cancellationPayoutHours = lateCancellationPeriod;
  }

  const hasNote = (identifier: WorkplaceNoteIdentifier): boolean => {
    return isDefined(workplaceNotes[identifier]);
  };

  const hasBreaks =
    (requiresLunchBreak === true && hasNote(WorkplaceNoteIdentifier.MANDATORY_BREAK_POLICY)) ||
    (providesRestBreaks === true && hasNote(WorkplaceNoteIdentifier.REST_BREAK_POLICY));

  if (hasBreaks) {
    highlightTypes.push(WorkplaceHighlightType.BREAKS);
  }

  if (hasNote(WorkplaceNoteIdentifier.DRESS_CODE_INSTRUCTIONS)) {
    highlightTypes.push(WorkplaceHighlightType.DRESS_CODE);
  }

  return highlightTypes.map((type) => ({
    type,
    label: getWorkplaceHighlightLabel(type, highlightLabelOptions),
  }));
};
